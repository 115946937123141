import { Preference } from '../models/preference';
import { Event } from '../models/event';

export class User {
    private _preference: Preference;
    previousEvent: Event;
    lastEvent: Event;
    alertDates: string[];
    email: string;
    hasTransitionPlan: boolean;
    hasBothMemoryPositionsSaved: boolean;
    needsToTakeSurvey: boolean;
    isSurveyRequired: boolean;
    isAdmin: boolean;
    isOrgAdmin: boolean;

    get preference(): Preference {
        return this._preference;
    }

    set preference(newPreference: Preference) {
        console.log('setting preference: ' + newPreference.sitInterval.toString());
        this._preference = newPreference;
        if (newPreference.sitInterval > 0) {
            console.log('has transition plan = true');
            this.hasTransitionPlan = true;
        } else {
            console.log('has transition plan = false');
            this.hasTransitionPlan = false;
        }

        if (newPreference.memoryOne > 0.0 && newPreference.memoryTwo > 0.0) {
            this.hasBothMemoryPositionsSaved = true;
        } else {
            this.hasBothMemoryPositionsSaved = false;
        }
    }
}
