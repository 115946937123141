import {Component, NgZone, OnInit} from '@angular/core';
import { RolesService } from '../roles.service';
import {NgbDate, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import 'moment-timezone';
import { UserService, DataStoreService, DataStoreType, Query } from 'kinvey-angular-sdk';
import {Team} from '../models/team';


@Component({
  selector: 'app-usage-chart',
  templateUrl: './usage-chart.component.html',
  styleUrls: ['./usage-chart.component.scss']
})
export class UsageChartComponent implements OnInit {
    hoveredDate: NgbDate;
    fromDate: NgbDate;
    toDate: NgbDate;
    isDatePickerVisible: boolean;
    protected dateRangeText;
    organizationName: string;
    teamStore;
    teams: Team[];
    selectedTeams: Team[];
    orgUserIds: String[];

    chartOptions = {
        responsive: true,
        scales: {
            xAxes: [{
                type: 'time',
                time: {
                    unit: 'day'
                }
            }]
        }};
    protected chartData: any;

  constructor(private rolesService: RolesService, private zone: NgZone, private userService: UserService,
              private dataStoreService: DataStoreService) {

  }

  ngOnInit() {
      const data: any = this.userService.getActiveUser().data;
      this.organizationName = data.organizationName;
      this.teamStore = this.dataStoreService.collection('Teams', DataStoreType.Network);
      const query = new Query();
      query.equalTo('OrganizationName', this.organizationName);
      this.selectedTeams = [];
      this.orgUserIds = [];
      this.teamStore.find(query)
          .subscribe((teams) => {
              this.zone.run(() => {
                  this.teams = teams;
                  // console.log(this.teams.length);
              });
          });
      this.rolesService.getUsersByOrganization(this.organizationName, [])
          .subscribe(users => {
            this.orgUserIds = users.map(u => u._id);
          });
  }

    isHovered(date: NgbDate) {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        return date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
        return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
    }

    showDateRangePicker() {
        this.isDatePickerVisible = true;
    }

    setDatePickerRange() {
        this.isDatePickerVisible = false;
        this.dateRangeText = this.fromDate.month.toString() + '/' + this.fromDate.day.toString() + '/' +
            this.fromDate.year.toString();
        if (this.toDate) {
            this.dateRangeText += ' - ' + this.toDate.month.toString() + '/' + this.toDate.day.toString() + '/' +
                this.toDate.year.toString();
        }
        this.createUsageData();
    }

    onDateSelection(date: NgbDate) {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
            this.toDate = date;
        } else {
            this.toDate = null;
            this.fromDate = date;
        }
    }

    ngbDateToString(date: NgbDate): string {
        // 2018-11-02T13:18:02.000Z
        const y = date.year.toString();
        const m = ((date.month.toString().length === 1) ? '0' : '') + date.month.toString();
        const d = ((date.day.toString().length === 1) ? '0' : '') + date.day.toString();
        return y + '-' + m + '-' + d;
    }

    onTeamCheck(event, team) {
      const index = this.selectedTeams.findIndex(t => t._id === team._id);
      if (event.target.checked) {
        if (index < 0 ) {
            this.selectedTeams.push(team);
        }
      } else {
        if (index > -1) {
            this.selectedTeams.splice(index, 1);
        }
      }
    }

    createUsageData() {

        if (this.chartData) {
            // this.chartData.length = 0;
            this.chartData = null;
        }
        const startDate = this.ngbDateToString(this.fromDate) + 'T00:00:00.000Z';
        let endDate;
        if (this.toDate) {
            endDate = this.ngbDateToString(this.toDate) + 'T23:59:59.999Z';
        } else {
            endDate = this.ngbDateToString(this.fromDate) + 'T23:59:59.999Z';
        }
        this.rolesService
            .getEventsByUserCollection(this.orgUserIds, startDate, endDate )
            .subscribe((events) => {
                const days = [];
                for (const event of events) {
                    const thisDate = moment.utc(event.timestamp).tz('America/New_York');
                    // console.log(thisDate.format('YYYY-MM-DD hh:mm A'));
                    thisDate.add(-4, 'hours'); // 4 AM cutoff
                    // console.log(thisDate.format('YYYY-MM-DD hh:mm A'));
                    const thisDayString = thisDate.format('YYYY-MM-DD');
                    let thisDay = days.find(d => d.day === thisDayString);
                    if (thisDay == null) {
                        thisDay = {'day': thisDayString, 'users': []};
                        days.push(thisDay);
                    }
                    let thisUser = thisDay.users.find(u => u.userid === event._acl.creator);
                    if (thisUser == null) {
                        thisUser = {'userid': event._acl.creator, 'sit': 0, 'stand': 0, 'active': false};
                        thisDay.users.push(thisUser);
                    }
                    if (event.value === 'sit' && event.duration >= 600) {
                        thisUser.sit += 1;
                    }
                    if (event.value === 'stand' && event.duration >= 600) {
                        thisUser.stand += 1;
                    }
                    if (thisUser.sit > 1 && thisUser.stand > 1) {
                        thisUser.active = true;
                    }
                }
                const orgData = [];
                const teamDatas = [];
                for (const team of this.selectedTeams) {
                  teamDatas.push([]);
                }
                const totalUserCount = this.orgUserIds.length;
                const daysInRange = [];
                const dayInRange = moment(this.ngbDateToString(this.fromDate));
                while (dayInRange.isBefore(moment(this.ngbDateToString(this.toDate)).add(1, 'days'))) {
                  daysInRange.push(dayInRange.format('YYYY-MM-DD'));
                  dayInRange.add(1, 'days');
                }
                // for (const day of days) {
                for (const day of daysInRange) {
                    // const dt = new Date(day.day);
                    const dt = moment(day).toDate();
                    // dt.setHours(0, 0, 0, 0);
                    const dataDay = days.find(d => d.day === day);
                    let activeUsers;
                    let activeUserCount;
                    if (dataDay) {
                        activeUsers = dataDay.users.filter(u => u.active) || [];
                        activeUserCount = activeUsers.length;
                    } else {
                      activeUsers = [];
                      activeUserCount = 0;
                    }
                    orgData.push ({t: dt, y: (activeUserCount / totalUserCount) * 100});
                    // get team counts for the day
                    let i = 0;
                    for (const team of this.selectedTeams) {
                      let teamActiveUserCount = 0;
                      const teamTotalUserCount = team.Users.length;
                      for (const activeUser of activeUsers) {
                        if (team.Users.includes(activeUser.userid)) {
                            teamActiveUserCount++;
                        }
                      }
                      const teamPercentActive = (teamTotalUserCount > 0) ? (teamActiveUserCount / teamTotalUserCount) * 100 : 0;
                      teamDatas[i].push({t: dt, y: teamPercentActive});
                      i++;
                    }

                }
                this.chartData = [
                    {data: orgData, label: 'Organization', lineTension: 0}
                ];
                let idx = 0;
                for (const teamData of teamDatas) {
                    this.chartData.push({data: teamData, label: this.selectedTeams[idx].TeamName, lineTension: 0});
                    idx ++;
                }
                console.log(teamDatas);
                // do something with results
                // console.log(days);
                // console.log(data);
            });
    }

}
