import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { } from 'kinvey-angular-sdk';

// TODO this is not recommended
const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json',
        'Authorization': 'Basic a2lkX0JrNXhmVlZJYjo2ZDQyMTE1OTBmZGE0ZmRjODVmY2YwYTliYzQyMTk3Zg==' })
};

@Injectable({
    providedIn: 'root'
})
export class RolesService {

    private kinveyUrl = 'https://baas.kinvey.com/';
    // private httpOptions;
    constructor(private http: HttpClient) {}

    getRoles(userid: string): Observable<any> {
        return this.http
            .get(this.kinveyUrl + 'user/kid_Bk5xfVVIb/' + userid + '/roles', httpOptions);
    }

    getUsersByOrganization(organizationName: string, userids: string[]): Observable<any> {
        const query = {};
        query['organizationName'] = organizationName;
        if (userids.length > 0) {
            query['_id'] = {'$in': userids};
        }
        return this.http
            .get(this.kinveyUrl + 'user/kid_Bk5xfVVIb?query=' + JSON.stringify(query), httpOptions);
    }

    getUsersByTeam(teamID: string): Observable<any> {
        const url = 'user/kid_Bk5xfVVIb?query={"teams": {"$in": ["' + teamID + '"]}}';
        console.log(url);
        return this.http
            .get( this.kinveyUrl + url, httpOptions);
    }
    getUserCount(organization: string, search: string): Observable<any> {
        const query = this.buildUserQuery(organization, search);
        const url = 'user/kid_Bk5xfVVIb/_count?query=' + query;
        return this.http
            .get(this.kinveyUrl + url, httpOptions);
    }
    getAllUsers(count: number, page: number): Observable<any> {
        const limit = count.toString();
        const skip = ((page - 1) * count).toString();
        const url = 'user/kid_Bk5xfVVIb/?query={}&limit=' + limit + '&skip=' + skip + '&sort={"_kmd.ect": -1}';
        // const url = 'user/kid_Bk5xfVVIb/?query={}&sort={"_kmd.ect": -1}';
        console.log(url);
        return this.http
            .get( this.kinveyUrl + url, httpOptions);
    }
    getAllUsersByOrganization(count: number, page: number, organization: string, sort: string,
                              direction: string, search: string): Observable<any> {
        const limit = count.toString();
        const skip = ((page - 1) * count).toString();
        const query = this.buildUserQuery(organization, search);
        let sortColumn: string;
        const directionNumber = (direction === 'desc') ? -1 : 1;
        if (sort === 'created') {
            sortColumn = '_kmd.ect';
        } else if (sort === 'email') {
            sortColumn = 'email';
        } else if (sort === 'organization') {
            sortColumn = 'organizationName';
        }
        const sortJson = {[sortColumn]: directionNumber};
        const url = 'user/kid_Bk5xfVVIb/?query=' + query + '&limit=' + limit + '&skip=' + skip +
            '&sort=' + JSON.stringify(sortJson);
        // const url = 'user/kid_Bk5xfVVIb/?query={}&sort={"_kmd.ect": -1}';
        console.log(url);
        return this.http
            .get( this.kinveyUrl + url, httpOptions);
    }
    getPreferencesByUser(userID: string): Observable<any> {
        const url = 'appdata/kid_Bk5xfVVIb/Preferences?query={"_acl.creator":"' + userID + '"}';
        // console.log(url);
        return this.http
            .get( this.kinveyUrl + url, httpOptions);
    }
    getEventCountByUser(userID: string): Observable<any> {
        // const url = 'appdata/kid_Bk5xfVVIb/Events/_count?query={"_acl.creator":"' + userID + '"}';
        // return this.http
        //    .get( this.kinveyUrl + url, httpOptions);
        const url = 'https://baas.kinvey.com/appdata/kid_Bk5xfVVIb/Events/_group';
        const jsonString = '{"key": {},"initial": {"count": 0,"maxTimestamp":0},' +
            '"reduce": "function(doc,out) { out.count++; if ((new Date(doc.timestamp)) > (new Date(out.maxTimestamp))) ' +
            'out.maxTimestamp = doc.timestamp; }",' +
            '"condition": {"_acl.creator": "' +
            userID + '"}}';
        return this.http
            .post(url, jsonString, httpOptions);
    }

    getEventStatsByUserCollection(users: any[], startDate: string, endDate: string): Observable<any> {
        // const url = 'appdata/kid_Bk5xfVVIb/Events/_count?query={"_acl.creator":"' + userID + '"}';
        // return this.http
        //    .get( this.kinveyUrl + url, httpOptions);
        const url = 'https://baas.kinvey.com/appdata/kid_Bk5xfVVIb/Events/_group';
        const json = {};
        json['key'] = {'_acl.creator': true};
        json['initial'] = {'transitions': 0, 'sit': 0, 'stand': 0};
        json['reduce'] = 'function(doc, out) { ';
        json['reduce'] += '    if (doc.durationadj > 600 && (doc.value === \'sit\' || doc.value === \'stand\')) { ';
        json['reduce'] += '        out.transitions++; ';
        json['reduce'] += '    } ';
        json['reduce'] += '    if (doc.value === \'sit\' && doc.durationadj) { ';
        json['reduce'] += '        out.sit += doc.durationadj; ';
        json['reduce'] += '    } ';
        json['reduce'] += '    if (doc.value === \'stand\' && doc.durationadj) { ';
        json['reduce'] += '        out.stand += doc.durationadj; ';
        json['reduce'] += '    } ';
        json['reduce'] += '} ';
        const userids = [];
        for (const user of users) {
            userids.push(user._id);
        }
        json['condition'] = {};
        json['condition']['_acl.creator'] = {'$in': userids};
        json['condition']['$and'] = [{'timestamp': {'$gte': startDate}},
            {'timestamp': {'$lte': endDate}}];
        const jsonString = JSON.stringify(json);
        console.log(jsonString);
        return this.http
            .post(url, jsonString, httpOptions);
    }

    getEventsByUserCollection(userids: String[], startDate: string, endDate: string): Observable<any> {
        const query = {'_acl.creator': {'$in': userids}};
        query['$and'] = [{'timestamp': {'$gte': startDate}},
            {'timestamp': {'$lte': endDate}}];
        const url = 'appdata/kid_Bk5xfVVIb/Events?query=' + JSON.stringify(query) + '&sort={"timestamp",1}';
        return this.http
            .get( this.kinveyUrl + url, httpOptions);
    }

    getSurveyResponsesByUser(userID: string): Observable<any> {
        const url = 'appdata/kid_Bk5xfVVIb/SurveyResponses?query={"_acl.creator":"' + userID + '"}';
        return this.http
            .get( this.kinveyUrl + url, httpOptions);
    }

    buildUserQuery(organization: string, search: string): string {
        const query = {};
        if (organization !== 'All') {
            if (organization === 'Individual') {
                query['organizationName'] = {'$in': [null, 'Individual']};
            } else {
                query['organizationName'] = organization;
            }
        }
        if (search && search !== '') {
            query['email'] = {'$regex': '^.*' + search };
        }
        return JSON.stringify(query);
    }

    getUser(userID: string): Observable<any> {
        const url = 'user/kid_Bk5xfVVIb?query={"_id": "' + userID + '"}';
        console.log(url);
        return this.http
            .get( this.kinveyUrl + url, httpOptions);
    }

    updateUser(user): Observable<any> {
        const url = 'user/kid_Bk5xfVVIb/' + user._id;
        const json = {};
        json['organizationName'] = user['organizationName'];
        json['email'] = user.email;
        json['isAdmin'] = user['isAdmin'];
        json['isOrgAdmin'] = user ['isOrgAdmin'];
        return this.http
            .put(this.kinveyUrl + url + '/?noorgcheck=true', JSON.stringify(json), httpOptions);
    }

    getEventStatsByUserCollection2(users: any[], startDate: string, endDate: string): Observable<any> {
        const url = 'https://baas.kinvey.com/appdata/kid_Bk5xfVVIb/Events/_group';
        const json = {};
        json['key'] = {'_acl.creator': true};
        json['initial'] = {'days' : [], 'sittime': 0, 'standtime': 0};
        json['reduce']  = 'function(doc, out) { ';
        json['reduce'] += '  var day = out.days.find(x => x.date === doc.timestamp.substring(0, 10));';
        json['reduce'] += '  if (!day) {';
        json['reduce'] += '    day = {\'date\' : doc.timestamp.substring(0, 10), \'sit\' : 0, \'stand\' : 0, \'transitions\' : 0,';
        json['reduce'] += '    \'sittime\' : 0, \'standtime\' : 0};';
        json['reduce'] += '    out.days.push(day);';
        json['reduce'] += '  }';
        json['reduce'] += '  if (doc.value === \'sit\') {';
        json['reduce'] += '    if (doc.durationadj > 600) {';
        json['reduce'] += '      day.sit += 1;';
        json['reduce'] += '    }';
        json['reduce'] += '    if (doc.durationadj) {';
        json['reduce'] += '      day.sittime += doc.durationadj;';
        json['reduce'] += '      out.sittime += doc.durationadj;';
        json['reduce'] += '    }';
        json['reduce'] += '  } else {';
        json['reduce'] += '    if (doc.durationadj > 600) {';
        json['reduce'] += '      day.stand += 1;';
        json['reduce'] += '    }';
        json['reduce'] += '    if (doc.durationadj) {';
        json['reduce'] += '      day.standtime += doc.durationadj;';
        json['reduce'] += '      out.standtime += doc.durationadj;';
        json['reduce'] += '    }';
        json['reduce'] += '  }';
        json['reduce'] += '  var min = (day.sit < day.stand) ? day.sit : day.stand;';
        json['reduce'] += '  day.transitions = min * 2;';
        json['reduce'] += '} ';
        const userids = [];
        for (const user of users) {
            userids.push(user._id);
        }
        json['condition'] = {};
        json['condition']['_acl.creator'] = {'$in': userids};
        json['condition']['$and'] = [
            {'timestamp': {'$gte': startDate}},
            {'timestamp': {'$lte': endDate}},
            {'value': {'$in': ['sit', 'stand'] }}
        ];
        const jsonString = JSON.stringify(json);
        // console.log(jsonString);
        return this.http
            .post(url, jsonString, httpOptions);
    }

    getSupportArticles() {
        const url = 'appdata/kid_Bk5xfVVIb/SupportArticles?query={}&sort=Sort';
        return this.http
            .get( this.kinveyUrl + url, httpOptions);
    }

    getBrand(name: string) {
        const url = 'rpc/kid_Bk5xfVVIb/custom/getBrand';
        const json = {'name': name};
        const jsonString = JSON.stringify(json);
        return this.http
            .post(this.kinveyUrl + url, jsonString, httpOptions);
    }
}
