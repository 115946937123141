import { Injectable } from '@angular/core';
import {Observable, throwError} from 'rxjs';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {OAuthService} from 'angular-oauth2-oidc';
import * as moment from 'moment';
import {SurveyResponse} from './models/survey-response';
import {SurveyAnswer} from './models/survey-answer';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    // private apiUrl = 'http://ec2-3-89-171-52.compute-1.amazonaws.com/api/';
    private apiUrl = 'https://api.standata.com/api/';
    private oAuthUrl = 'https://api.standata.com/oauth/';

    constructor(private http: HttpClient, private oAuthService: OAuthService) {}

    getUser(): Observable<any> {
        return this.http
            .get(this.apiUrl + 'user')
            .pipe(catchError(this.handleError));
    }

    logOut(): Observable<any> {
        return this.http
            .post(this.oAuthUrl + 'logout', null);
    }

    getUserPref(): Observable<any> {
        return this.http
            .get(this.apiUrl + 'user_pref');
    }

    putUserPref(userPref): Observable<any> {
        return this.http
            .put(this.apiUrl + 'user_pref', userPref);
    }

    putUser(user): Observable<any> {
        return this.http
            .put(this.apiUrl + 'user', user);
    }

    logEvent(event): Observable<any> {
        return this.http
            .post(this.apiUrl + 'event', event)
            .pipe(catchError(this.handleError));
    }

    getEventSummary(startDate: moment.Moment, endDate: moment.Moment): Observable<any> {
        return this.http
            .get(this.apiUrl + 'event/summary?startDate=' + startDate.format('YYYY-MM-DDTHH:mm:ss.SSS') +
            '&endDate=' + endDate.format('YYYY-MM-DDTHH:mm:ss.SSS'));
    }

    getSupportArticle(): Observable<any> {
        return this.http
            .get(this.apiUrl + 'support_article');
    }

    getSurvey(name: string): Observable<any> {
        return this.http
            .get(this.apiUrl + 'survey?name=' + name);
    }

    saveSurveyResponse(surveyReponse: SurveyResponse): Observable<any> {
        return this.http
            .post(this.apiUrl + 'survey_response', surveyReponse)
            .pipe(catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 401) {
            console.log('401 Unauthorized Error');
            console.log(this.oAuthService);
        }
        return throwError('There was an error with the request.');
    }
}
