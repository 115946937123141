import {Component, OnInit, NgZone, ChangeDetectorRef, OnDestroy, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'kinvey-angular-sdk';
import { SDUserService} from '../user.service';
import {User} from '../models/user';
import {Subscription} from 'rxjs';
import {ActivityService} from '../activity.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CookieService} from 'ngx-cookie-service';
import {KinveyModule} from 'kinvey-angular-sdk/lib';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

    username: string;
    password: string;
    error: string;
    user: User;
    subscription: Subscription;
    recoverPasswordEmail: string;
    alertTitle: string;
    alertMessage: string;

  constructor(private router: Router, private ngZone: NgZone, private cd: ChangeDetectorRef,
              private sdUserService: SDUserService, private activityService: ActivityService,
              private modalService: NgbModal, private userService: UserService, private cookieService: CookieService) { }

    @ViewChild('recoverPasswordModal', { static: true }) private recoverPasswordModal;
    @ViewChild('alertModal', { static: true }) private alertModal;

    ngOnInit() {
        this.subscription = new Subscription();
        const userSubscription = this.sdUserService.getUser().subscribe((user) => {
            this.user = user;
            if (this.user) {
                this.ngZone.run(() => {
                    console.log('navigate to home');
                    this.router.navigate(['/']);
                });
            }
        });
        this.subscription.add(userSubscription);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    login() {
        console.log('login pressed');
        this.error = undefined;

        // odd issue with user is already logged in
        const activeUser = this.userService.getActiveUser();
        if (activeUser) {
            console.log('active user, log out before logging in');
            this.userService.logout()
                .then(() => {
                    console.log('logged out, now log in');
                    this.cookieService.delete('standata.user');
                    this.sdUserService.refreshUser();
                    this.tryLogin();
                });
        } else {
            console.log('no active user, go ahead and log in');
            this.tryLogin();
        }

    }

    tryLogin() {
        console.log('attemtping log in');
        this.activityService.isWaiting = true;
        this.userService.login(this.username, this.password)
            .then(() => {
                console.log('login successful, refresh user');
                this.sdUserService.refreshUser();
            })
            .catch(error => {
                console.log('login not successful');
                this.activityService.isWaiting = false;
                this.error = error;
            });
    }

    recoverPassword(event) {
        event.preventDefault();
        this.modalService.open(this.recoverPasswordModal).result.then((result) => {
            // recover password
            this.activityService.isWaiting = true;
            this.userService.resetPassword(this.recoverPasswordEmail)
                .then(() => {
                    this.activityService.isWaiting = false;
                    // this.ngZone.run(() => {
                    this.showAlert('Password Reset Email Sent', 'We\'ve sent an email to ' +
                        this.recoverPasswordEmail + ' to reset your password. If you don\'t receive the email ' +
                        'within a few minutes, check your Junk, Spam or Clutter folders.');
                    // });
                })
                .catch(error => {
                    this.activityService.isWaiting = false;
                    // this.ngZone.run(() => {
                    this.showAlert('Error', error);
                    // });
                });

        }).catch((error) => {
            // do nothing
        });
    }

    showAlert(title, message) {
        this.alertTitle = title;
        this.alertMessage = message;
        this.modalService.open(this.alertModal);
    }

}
