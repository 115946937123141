import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class ActivityService {
    private isWaitingSubject: BehaviorSubject<boolean>;
    private isHomeBusySubject: BehaviorSubject<boolean>;
    constructor() {
        this.isWaitingSubject = new BehaviorSubject(false);
        this.isHomeBusySubject = new BehaviorSubject(false);
    }
    set isWaiting(isWaiting: boolean) {
        this.isWaitingSubject.next(isWaiting);
    }
    public isWaitingObservable(): Observable<boolean> {
        return this.isWaitingSubject.asObservable();
    }
    set isHomeBusy(isHomeBusy: boolean) {
        this.isHomeBusySubject.next(isHomeBusy);
    }
    public isHomeBusyObservable(): Observable<boolean> {
        return this.isHomeBusySubject.asObservable();
    }
}
