import {NgModule, Injectable, OnDestroy} from '@angular/core';
import { CanActivate, RouterModule, Routes, Router } from '@angular/router';
import { OrgAdminComponent } from './org-admin/org-admin.component';
import { UserDataComponent } from './user-data/user-data.component';
import { UsageChartComponent } from './usage-chart/usage-chart.component';
import { LoginOauthComponent } from './login/login-oauth.component';
import { SDUserService } from './user.service';
import {User} from './models/user';
import {Observable, Subscription} from 'rxjs';
import {AdminLayoutComponent} from './admin-layout/admin-layout.component';
import {OAuthService} from 'angular-oauth2-oidc';
import {LoginComponent} from './login/login.component';
import {SignupComponent} from './signup/signup.component';

@Injectable()
export class NoAuthGuard implements CanActivate, OnDestroy { // read as "guard against no authentication"

    // private user: User = null;
    // private subscription: Subscription;

    constructor(private router: Router, private oAuthService: OAuthService/*, private sdUserService: SDUserService*/) {
        /*this.subscription = new Subscription();
        const userSubscription = this.sdUserService.getUser().subscribe((user) => {
            this.user = user;
        });
        this.subscription.add(userSubscription);*/
    }

    canActivate() {
        // console.log('NoAuthGuard canActivate');

        if (/*this.user*/ this.oAuthService.hasValidAccessToken()) {
            return true;
        }

        // Navigate to the login page
        // this.router.navigate(['/login']);
        this.oAuthService.initCodeFlow();
        return false;
    }

    ngOnDestroy() {
        // this.subscription.unsubscribe();
    }
}

@Injectable()
export class NoOauthGuard implements CanActivate {
    canActivate() {
        return true;
    }
}

@Injectable()
export class NoOrgAdminGuard implements CanActivate, OnDestroy {

    private user: User = null;
    private subscription: Subscription;

    constructor(private router: Router, private sdUserService: SDUserService) {
        this.subscription = new Subscription();
        const userSubscription = this.sdUserService.getUser().subscribe((user) => {
            this.user = user;
        });
        this.subscription.add(userSubscription);
    }
    canActivate() {
        console.log('NoOrgAdminGuard canActivate');
        if (this.user) {
            if (this.user.isOrgAdmin) {
                return true;
            } else {
                this.router.navigate(['/']);
                return false;
            }
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}

@Injectable()
export class NoAdminGuard implements CanActivate, OnDestroy {

    private user: User = null;
    private subscription: Subscription;

    constructor(private router: Router, private sdUserService: SDUserService) {
        this.subscription = new Subscription();
        const userSubscription = this.sdUserService.getUser().subscribe((user) => {
            this.user = user;
        });
        this.subscription.add(userSubscription);
    }
    canActivate() {
        console.log('NoAdminGuard canActivate');
        if (this.user) {
            if (this.user.isAdmin) {
                console.log('user is admin');
                return true;
            } else {
                this.router.navigate(['/']);
                return false;
            }
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}

const routes: Routes = [
    {path: '', redirectTo: '/connect', pathMatch: 'full'},
    { path: 'login', component: LoginComponent },
    { path: 'signup', component: SignupComponent },
    { path: 'loginoauth', component: LoginOauthComponent},
    {
        path: 'admin', redirectTo: '/admin/orgadmin', pathMatch: 'full'
    },
    {
        path: 'admin',
        component: AdminLayoutComponent,
        children: [
            { path: 'userdata', component: UserDataComponent, canActivate: [NoAdminGuard]},
            { path: 'orgadmin', component: OrgAdminComponent, canActivate: [NoOrgAdminGuard] },
            { path: 'usagechart', component: UsageChartComponent, canActivate: [NoOrgAdminGuard] },
        ]
    },
    { path: '', loadChildren: () => import('./front-end/front-end.module').then(m => m.FrontEndModule) },
    { path: 'relay', loadChildren: () => import('./relay/relay.module').then(m => m.RelayModule) },
    { path: 'sensor', loadChildren: () => import('./sensor/sensor.module').then(m => m.SensorModule) },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ],
    providers: [
      NoAuthGuard,
      NoOrgAdminGuard,
      NoAdminGuard
    ]
})
export class AppRoutingModule {}
