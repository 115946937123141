import { Component, OnInit, NgZone } from '@angular/core';
import { DataStoreService, DataStoreType, Query } from 'kinvey-angular-sdk';
import { RolesService } from '../roles.service';
import { Preference } from '../models/preference';
import { Event } from '../models/event';
import {isUndefined} from 'util';
import { SurveyResponse} from '../models/survey-response';
import {Survey} from '../models/survey';
import {Organization} from '../models/organization';

@Component({
  selector: 'app-user-data',
  templateUrl: './user-data.component.html',
  styleUrls: ['./user-data.component.scss'],
  providers: [RolesService]
})
export class UserDataComponent implements OnInit {

  users: any[];
  isLoading: boolean;
  selectedUser: any;
  count: number;
  page: number;
  totalUsers: number;
  surveyQuestions: Survey[];
  surveyResponses: SurveyResponse[];
  protected organizations: string[];
  protected selectedOrganization: string;
  private sortColumn: string;
  private sortDirection: string;
  protected searchTerm: string;

  constructor(private rolesService: RolesService, private zone: NgZone, private dataStoreService: DataStoreService) { }

  ngOnInit() {
      this.selectedUser = null;
      this.count = 25;
      this.page = 1;
      const store = this.dataStoreService.collection('Survey', DataStoreType.Network);
      store.find()
          .subscribe((surveys) => {
              this.zone.run(() => {
                  this.surveyQuestions = surveys;
              });
          });
      this.selectedOrganization = 'All';
      this.organizations = [];
      this.sortColumn = 'created';
      this.sortDirection = 'desc';
      this.searchTerm = '';
      this.refreshData();
      const orgStore = this.dataStoreService.collection('Organizations', DataStoreType.Network);
      const query = new Query();
      query.ascending('OrganizationName');
      orgStore.find(query)
          .subscribe((organizations) => {
              this.zone.run(() => {
                  for (const organization of organizations) {
                      this.organizations.push(organization.OrganizationName);
                  }
              });
          });

        /*
      const array = [
          {'id': 1, 'group': 'a', 'subgroup': '1', 'details': {'d1': 'd1-1', 'd2': 'd2-1'}},
          {'id': 2, 'group': 'b', 'subgroup': '2', 'details': {'d1': 'd1-2', 'd2': 'd2-2'}},
          {'id': 3, 'group': 'a', 'subgroup': '1', 'details': {'d1': 'd1-3', 'd2': 'd2-3'}},
          {'id': 4, 'group': 'b', 'subgroup': '2', 'details': {'d1': 'd1-4', 'd2': 'd2-4'}},
          {'id': 5, 'group': 'a', 'subgroup': '1', 'details': {'d1': 'd1-5', 'd2': 'd2-5'}},
          {'id': 6, 'group': 'b', 'subgroup': '2', 'details': {'d1': 'd1-6', 'd2': 'd2-6'}},
          {'id': 7, 'group': 'a', 'subgroup': '1', 'details': {'d1': 'd1-7', 'd2': 'd2-7'}},
          {'id': 8, 'group': 'b', 'subgroup': '2', 'details': {'d1': 'd1-8', 'd2': 'd2-8'}}
      ];
      function myReduceFunction(rootArray, itemToBeGrouped) {
          const groupId = itemToBeGrouped.group + itemToBeGrouped.subgroup; // groupId = 'a1'
          rootArray[groupId] = rootArray[groupId] || []; // short circuit
          rootArray[groupId].push(itemToBeGrouped);
          return rootArray;
      }


      const groups = array.reduce(myReduceFunction);
      console.log(groups);
      */
  }

  refreshData() {
      this.users = [];
      this.isLoading = true;
      // get count of total users
      this.rolesService
          .getUserCount(this.selectedOrganization, this.searchTerm)
          .subscribe((response) => {
              this.totalUsers = response.count;
              let i = 0;
              // get paged list of users
              this.rolesService
                  .getAllUsersByOrganization(this.count, this.page, this.selectedOrganization,
                      this.sortColumn, this.sortDirection, this.searchTerm)
                  .subscribe((users) => {
                      for (const user of users) {
                          const row = {id: '', email: '', organization: '', created: new Date(), survey: null, myHeight: '',
                              memoryOne: 0, memoryTwo: 0, events: 0, lastevent: null };
                          row.id = user._id;
                          row.email = user.email;
                          row.organization = user.organizationName;
                          row.created = new Date(user._kmd.ect);
                          this.users.push(row);
                          this.rolesService
                              .getPreferencesByUser(user._id)
                              .subscribe((preferences) => {
                                  if (preferences.length > 0) {
                                      if (preferences[0].myHeight) {
                                          const feet = Math.floor(preferences[0].myHeight / 12);
                                          const inches = preferences[0].myHeight % 12;
                                          row.myHeight = '' + feet + '\' ' + inches + '"';
                                      }
                                      if (preferences[0].memoryOne) {
                                          row.memoryOne = preferences[0].memoryOne;
                                      }
                                      if (preferences[0].memoryTwo) {
                                          row.memoryTwo = preferences[0].memoryTwo;
                                      }
                                      if (preferences[0].lastSurvey) {
                                          row.survey = new Date(preferences[0].lastSurvey);
                                      }
                                  }
                                  this.rolesService
                                      .getEventCountByUser(user._id)
                                      .subscribe((result) => {
                                          if (result[0] !== void 0) {
                                              row.events = result[0].count;
                                              row.lastevent = new Date(result[0].maxTimestamp);
                                          }
                                          i++;
                                          if (i === users.length) {
                                              console.log('done');
                                              this.isLoading = false;
                                              // this.onUserSelect(this.users[0].id);
                                          }
                                      });

                              });
                      }
                  });
          });

  }

  onUserSelect(user) {
      this.selectedUser = user;
      this.surveyResponses = [];
      this.rolesService
          .getSurveyResponsesByUser(user.id)
          .subscribe( (responses) => {
              this.surveyResponses = responses;
              const sr: SurveyResponse = responses[0];
              /*
              var members = [
  {name: "john", team: 1},
  {name: "kevin", team: 1},
  {name: "rob", team: 2},
  {name: "matt", team: 2},
  {name: "clint", team: 3},
  {name: "will", team: 3}
];
var groups = members.reduce(function(obj,item){
    obj[item.team] = obj[item.team] || [];
    obj[item.team].push(item.name);
    return obj;
}, {});
var myArray = Object.keys(groups).map(function(key){
    return {team: key, name: groups[key]};
});
console.log(myArray);
               */
              const groups = responses.reduce(function(obj, item) {
                  obj[item.SurveyName] = obj[item.SurveyName] || [];
                  obj[item.SurveyName].push(item);
                  return obj;
              }, {});
              console.log(groups);
              const ma = Object.keys(groups).map(function(key) {
                  return {surveyName: key, responses: groups[key] };
              });
              console.log(ma);

          });
  }

  deselectUser() {
      this.selectedUser = null;
  }
  onCountChange(count) {
      this.count = count;
      this.page = 1;
      this.refreshData();
  }
  onPageChange() {
      this.refreshData();
  }
  onOrganizationChange(org: string) {
      this.selectedOrganization = org;
      this.refreshData();
  }
  onSortClick(sort: string) {
      if (sort === this.sortColumn) {
          if (this.sortDirection === 'desc') {
              this.sortDirection = 'asc';
          } else {
              this.sortDirection = 'desc';
          }
      } else {
          this.sortColumn = sort;
          this.sortDirection = 'asc';
      }
      this.refreshData();
  }

    onUserOrganizationChange(organization) {
      this.selectedUser.organization = organization;
      this.rolesService.getUser(this.selectedUser.id)
          .subscribe((users: any[]) => {
              const user = users[0];
              user['organizationName'] = organization;
              this.rolesService.updateUser(user)
                  .subscribe((result) => {
                      // no need to do anything
                  });
          });
    }

}
