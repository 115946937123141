import { Component, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { UserService, DataStoreService, DataStoreType, Query } from 'kinvey-angular-sdk';
import { Team } from '../models/team';
import { RolesService } from '../roles.service';
import {NgbDate, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import 'moment-timezone';

@Component({
  selector: 'app-org-admin',
  templateUrl: './org-admin.component.html',
  styleUrls: ['./org-admin.component.scss'],
  providers: [RolesService]
})
export class OrgAdminComponent implements OnInit {
    teams: Team[];
    selectedTeam: Team;
    users: any[];
    selectedUser: any;
    selectedMenu: string;
    members: any[];
    organizationName: string;
    teamStore;
    userStats: any[];

    hoveredDate: NgbDate;
    fromDate: NgbDate;
    toDate: NgbDate;
    isDatePickerVisible: boolean;
    protected dateRangeText;
    protected teamFilter: Team;
    private totalTransitions;
    private totalSit;
    private totalStand;
    private totalStandPercent;
    private totalCalories;

  constructor(private cd: ChangeDetectorRef, private rolesService: RolesService, private zone: NgZone,
              calendar: NgbCalendar, private userService: UserService, private dataStoreService: DataStoreService) {
      this.fromDate = calendar.getToday();
      this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);
  }

    onChartClick(event) {
        console.log(event);
    }

  ngOnInit() {
    this.selectedMenu = '';
    const data: any = this.userService.getActiveUser().data;
    this.organizationName = data.organizationName;
    this.teamStore = this.dataStoreService.collection('Teams', DataStoreType.Network);
    this.isDatePickerVisible = false;
    this.teamFilter = null;
    // this.chartData = [{ data: [330, 600, 260, 700], label: 'Account A' }];
  }
  onMenuSelect(menu) {
        this.deselectAll();
        this.selectedMenu = menu;
        if (menu === 'Users') {
            if (!this.users || this.teamFilter) {
                this.getUsers(null);
            }
        }
        if (menu === 'Teams') {
            if (!this.teams) {
                this.getTeams();
            }
        }
        if (menu === 'Stats') {
            this.getUsers(this.teamFilter);
            if (!this.teams) {
                this.getTeams();
            }
        }
        if (menu === 'Usage') {
            // something here?
        }
  }

  getTeams() {

      const query = new Query();
      query.equalTo('OrganizationName', this.organizationName);
      this.teamStore.find(query)
          .subscribe((teams) => {
              this.zone.run(() => {
                  this.teams = teams;
              });
          });
  }

  getUsers(team: Team) {
      /*const store = Kinvey.DataStore.collection<Kinvey.User>('Users');
        store.find()
            .subscribe((users) => {
                this.users = users;
                this.cd.detectChanges();
            });*/
      let userids;
      if (team) {
          userids = team.Users;
      } else {
          userids = [];
      }
      this.rolesService
          .getUsersByOrganization(this.organizationName, userids)
          .subscribe((users) => {
              this.users = users;
              const startDate = this.ngbDateToString(this.fromDate) + 'T00:00:00.000Z';
              let endDate;
              if (this.toDate) {
                  endDate = this.ngbDateToString(this.toDate) + 'T23:59:59.999Z';
              } else {
                  endDate = this.ngbDateToString(this.fromDate) + 'T23:59:59.999Z';
              }
              this.rolesService
                  .getEventStatsByUserCollection(this.users, startDate, endDate )
                  .subscribe((results) => {
                      // do something with results
                      this.userStats = results;
                      this.totalTransitions = 0;
                      this.totalSit = 0;
                      this.totalStand = 0;
                      for (const userStat of this.userStats) {
                          this.totalTransitions += userStat.transitions;
                          this.totalSit += (userStat.sit / 60);
                          this.totalStand += (userStat.stand / 60);
                      }
                      const totalTime = (this.totalSit + this.totalStand);
                      this.totalStandPercent = ((totalTime > 0) ? (this.totalStand / totalTime) : 0) * 100;
                      this.totalCalories = this.totalStand * 0.7;
                  });
          });
      }

      getStat(user) {
        if (this.userStats) {
            const userStat = this.userStats.find(stat => stat['_acl.creator'] === user._id);
            if (userStat) {
                const sitMinutes = userStat['sit'] / 60;
                const standMinutes = userStat['stand'] / 60;
                const totalMinutes = sitMinutes + standMinutes;
                const standPercent = (totalMinutes > 0) ? ((standMinutes / totalMinutes) * 100) : 0;
                return {
                    'transitions': userStat['transitions'],
                    'stand': standMinutes,
                    'sit': sitMinutes,
                    'standPercent': standPercent,
                    'calories': standMinutes * 0.7
                };
            }
        }
        return {
            'transitions': 0,
            'stand': 0,
            'sit': 0,
            'standPercent': 0,
            'calories': 0
        };
      }

  onUserSelect(user) {
      this.selectedUser = user;
      this.selectedTeam = null;
      if (!this.teams) {
          this.getTeams();
      }
  }
  onTeamSelect(team) {
      this.selectedTeam = team;
      this.selectedUser = null;
      if (!this.users) {
          this.getUsers(null);
      }
  }
  deselectAll() {
      this.selectedUser = null;
      this.selectedTeam = null;
  }
  onToggleUserInTeam(user, team: Team) {
      if (team.Users.includes(user._id)) {
          const index: number = team.Users.indexOf(user._id);
          team.Users.splice(index, 1);
      } else {
          team.Users.push(user._id);
      }
  }
  onAddTeam() {
      const team: Team = new Team();
      team.OrganizationName = this.organizationName;
      team.Users = [];
      this.onTeamSelect(team);
  }
  onSaveTeam(team: Team) {
      if (!team._id) {
          this.teams.push(team);
      }
      this.teamStore.save(Object.assign({}, team));
      this.selectedTeam = null;
  }
    onDeleteTeam(team: Team) {
        const index: number = this.teams.indexOf(team);
        this.teams.splice(index, 1);
        this.teamStore.removeById(team._id);
        this.selectedTeam = null;
    }

    onDateSelection(date: NgbDate) {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
            this.toDate = date;
        } else {
            this.toDate = null;
            this.fromDate = date;
        }
    }

    isHovered(date: NgbDate) {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        return date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
        return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
    }

    showDateRangePicker() {
      this.isDatePickerVisible = true;
    }

    setDatePickerRange() {
        this.isDatePickerVisible = false;
        this.dateRangeText = this.fromDate.month.toString() + '/' + this.fromDate.day.toString() + '/' +
            this.fromDate.year.toString();
        if (this.toDate) {
            this.dateRangeText += ' - ' + this.toDate.month.toString() + '/' + this.toDate.day.toString() + '/' +
            this.toDate.year.toString();
        }
        this.getUsers(this.teamFilter);
    }

    ngbDateToString(date: NgbDate): string {
      // 2018-11-02T13:18:02.000Z
      const y = date.year.toString();
      const m = ((date.month.toString().length === 1) ? '0' : '') + date.month.toString();
      const d = ((date.day.toString().length === 1) ? '0' : '') + date.day.toString();
      return y + '-' + m + '-' + d;
    }

    onTeamFilterChange(team: Team) {
      this.teamFilter = team;
      this.getUsers(this.teamFilter);
    }

    parseISOString(s) {
        const b = s.split(/\D+/);
        return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
    }

}
