import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { KinveyModule } from 'kinvey-angular-sdk';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));

/*
const config: Kinvey.ClientConfig = {
    appKey: 'kid_Bk5xfVVIb',
    appSecret: '65eb6b39c5b141bb92454229eaa3f9d1'
};

Kinvey.initialize(config)
    .then(() => {
        if (environment.production) {
            enableProdMode();
        }
        platformBrowserDynamic().bootstrapModule(AppModule)
            .catch(err => console.log(err));
    });
*/
