import {
    Component,
    Input
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {NgForm} from '@angular/forms';

@Component({
    selector: 'set-height-modal-component',
    template: `
      <div class="modal-header">
          <h4 class="modal-title">Current Desk Height</h4>
      </div>
      <div class="modal-body">
          <form #heightForm="ngForm" name="heightForm" id="heightForm" (ngSubmit)="save(heightForm)" novalidate>
              <div class="form-group">
                  <label>Please enter your current desk height in inches</label>
                  <input type="number" class="form-control" id="heightIn" name="heightIn" [(ngModel)]="heightIn"
                         #refHeightIn="ngModel" required
                         [class.is-invalid]="refHeightIn.errors && (refHeightIn.dirty || heightForm.submitted)" />
                  <small class="text-danger" *ngIf="refHeightIn.errors && (refHeightIn.dirty || heightForm.submitted)">
                      A numeric value is required</small>
              </div>
          </form>
      </div>
      <div class="modal-footer">
          <button class="btn btn-outline-dark" type="button"
                  (click)="heightForm.onSubmit($event)" >Done</button>
      </div>
  `,
    styles: [`
        .close {
            outline: none
        }
    `]
})

export class SetHeightModalComponent {

    public heightIn;
    constructor(public activeModal: NgbActiveModal) {}

    save(f: NgForm) {
        if (f.valid) {
            this.activeModal.close(this.heightIn);
        }
    }
}

