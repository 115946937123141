

export class Event {
    _id: string;
    name: string;
    value: string;
    timestamp: string;
    duration: number;
    version: string;
    durationadj: number;
}
