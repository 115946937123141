import { Component, OnInit, NgZone, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'kinvey-angular-sdk';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {ActivityService} from '../activity.service';
import {SDUserService} from '../user.service';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  user: any;
  // user: Kinvey.User;
  username: string;
  password: string;
  confirmPassword: string;
  error;
  errorMessage: string;
  @ViewChild('signupSuccessModal', { static: true }) private signupSuccessModal;

  constructor(private router: Router, private ngZone: NgZone, private modalService: NgbModal,
              private cd: ChangeDetectorRef, private activityService: ActivityService,
              private userService: UserService, private sdUserService: SDUserService, private cookieService: CookieService) { }

  ngOnInit() {
    this.errorMessage = '';
  }

  signup() {
      if (this.confirmPassword === this.password) {
          this.error = undefined;
          this.activityService.isWaiting = true;
          this.userService.signup({'username': this.username, 'password': this.password, 'email': this.username })
              .then(() => {
                  this.ngZone.run(() => {
                      this.userService.logout()
                          .then(() => {
                              this.cookieService.delete('standata.user');
                              this.sdUserService.refreshUser();
                          });
                      this.modalService.open(this.signupSuccessModal).result.then((result) => {
                          this.router.navigate(['/login']);
                      });
                  });
                  this.activityService.isWaiting = false;
              })
              .catch((error) => {
                  /*this.ngZone.run(() => {
                      this.isLoading = false;
                      this.error = error;
                      this.errorMessage = this.error.message;
                  });*/
                  this.activityService.isWaiting = false;
                  this.error = error;
                  this.errorMessage = this.error.message;
                  this.cd.detectChanges();
              });
      } else {
          this.errorMessage = 'Passwords do not match.';
      }
  }

}
