import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { ChartsModule } from 'ng2-charts';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';

import { SignupComponent } from './signup/signup.component';

import { OrgAdminComponent } from './org-admin/org-admin.component';

import { LoginOauthComponent } from './login/login-oauth.component';

import { SDUserService } from '../app/user.service';
import { UserDataComponent } from './user-data/user-data.component';

import { CookieService } from 'ngx-cookie-service';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { UsageChartComponent } from './usage-chart/usage-chart.component';


import { KinveyModule } from 'kinvey-angular-sdk';

import { OAuthModule} from 'angular-oauth2-oidc';
import {NoSanitizePipe} from './no-sanitize-pipe';
import {ModalComponent} from './modal.component';
import {SetHeightModalComponent} from './sensor/set-height-modal.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        SignupComponent,
        OrgAdminComponent,
        UserDataComponent,
        AdminLayoutComponent,
        UsageChartComponent,
        LoginOauthComponent,
        ModalComponent,
        SetHeightModalComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        NgbModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        ChartsModule,
        KinveyModule.init({
            appKey: 'kid_Bk5xfVVIb',
            appSecret: '65eb6b39c5b141bb92454229eaa3f9d1'
        }),
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: ['http://ec2-3-89-171-52.compute-1.amazonaws.com/api', 'https://api.standata.com/'],
                sendAccessToken: true
            }
        })
    ],
    entryComponents: [ModalComponent, SetHeightModalComponent],
    providers: [SDUserService, CookieService],
    bootstrap: [AppComponent]
})
export class AppModule { }
