import {Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {OAuthEvent, OAuthService, OAuthSuccessEvent} from 'angular-oauth2-oidc';
import {ApiService} from '../api-service';

@Component({
    selector: 'app-login',
    templateUrl: './login-oauth.component.html',
    styleUrls: ['./login-oauth.component.scss']
})
export class LoginOauthComponent implements OnInit {
    private loginLogout = 'Log In';
    constructor(private activatedRoute: ActivatedRoute, private oAuthService: OAuthService,
                private apiService: ApiService) {}
    ngOnInit(): void {
        this.oAuthService.events.subscribe((oAuthEvent: OAuthEvent) => {
            if (oAuthEvent.type === 'token_received' ||
                oAuthEvent.type === 'token_refreshed' ||
                oAuthEvent.type === 'logout') {
                this.loginLogout = (this.oAuthService.hasValidAccessToken()) ? 'Log Out' : 'Log In';
            }
        });
    }

    /*
    http://ec2-3-89-171-52.compute-1.amazonaws.com/oauth/authorize?response_type=code
    &client_id=web-v1
    &state=1Go0Qh4kT8aoT6zIOMchZDOnFWOIHAYx1_om6sXgpXw
    &scope=read+write
    &code_challenge=IhWrdgqWSZFrf6Yb159WyKUPm9qClDup54LL5zQBB7o
    &code_challenge_method=S256
    &redirect_uri=http://ec2-3-89-171-52.compute-1.amazonaws.com
    */

    logInOrOut() {
        if (this.oAuthService.hasValidAccessToken()) {
            // log out
            // kind of hacky, but this makes it work
            // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/504
            sessionStorage.setItem('id_token', 'nothing');
            this.oAuthService.logOut(false);
            /*// alternatively
            this.apiService.logOut().toPromise().then(resp => {
                console.log(resp);
                this.oAuthService.logOut(true);
            });*/
        } else {
            // log in
            // console.log('initiating code flow');
            this.oAuthService.initCodeFlow();
        }
    }

    getUser() {
        this.apiService.getUser().toPromise().then(user => {
            console.log(user);
        });
    }
}
