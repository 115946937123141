import {Injectable, NgZone} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
// import { DataStoreService, UserService, EndpointService, DataStoreType } from 'kinvey-angular-sdk';
import { Preference } from './models/preference';
import { Event } from './models/event';
import { User } from './models/user';
import { HttpClient } from '@angular/common/http';
import {Organization} from './models/organization';
import {ActivityService} from './activity.service';
import {CookieService} from 'ngx-cookie-service';
import * as moment from 'moment';
import {OAuthService} from 'angular-oauth2-oidc';
import {ApiService} from './api-service';

// import {share} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SDUserService {
    private user: User;
    private userSubject: BehaviorSubject<User> = new BehaviorSubject(null);
    private isInitialized: boolean;
    public getUser(): Observable<User> {
        return this.userSubject.asObservable();
    }

    constructor(private http: HttpClient, private activityService: ActivityService,
                /*private userService: UserService, */private ngZone: NgZone, /*private endpointService: EndpointService, */
                /*private dataStoreService: DataStoreService, */private cookieService: CookieService,
                private oAuthService: OAuthService, private apiService: ApiService) {
        console.log('user service constructor');
        this.refreshUser();
    }

    public async getUserPromise(): Promise<User> {
        return new Promise(async (resolve, reject) => {
            if (this.user) {
                resolve(this.user);
            } else {
                await this.refreshUser();
                resolve(this.user);
            }
        });
    }

    async refreshUser() {
        this.activityService.isWaiting = true;
        if (this.oAuthService.hasValidAccessToken()) {
            this.user = new User();
            let cookieString = '';
            if (this.cookieService.check('standata.user')) {
                cookieString = this.cookieService.get('standata.user');
                const userObject = JSON.parse(cookieString);
                Object.assign(this.user, userObject);
                this.userSubject.next(this.user);
            }
            const userDetails: any = await this.apiService.getUser().toPromise();
            this.activityService.isWaiting = false;
            this.user.email = userDetails.email;
            const roles: string[] = userDetails.roles;
            this.user.isAdmin = (roles.includes('admin'));
            this.user.isOrgAdmin = (roles.includes('org-admin'));
            const userPref = userDetails.userPref;
            if (userPref) { // should always have one
                const preference = new Preference();
                preference.sitInterval = userPref.sitInterval;
                preference.myHeight = userPref.height;
                preference.standInterval = userPref.standInterval;
                preference.memoryOne = userPref.memoryOne;
                preference.memoryTwo = userPref.memoryTwo;
                preference.birthYear = userPref.birthYear;
                preference.purchaseDate = userPref.datePurchase;
                preference.lastSurvey = userPref.dateSurvey;
                preference.queuedSurveyId = userPref.queuedSurveyId;
                this.user.preference = preference;
                if (!this.user.preference.lastSurvey || this.user.preference.lastSurvey.length === 0 ||
                    (this.user.preference.queuedSurveyId && this.user.preference.queuedSurveyId > 0)) {
                    this.user.needsToTakeSurvey = true;
                } else {
                    this.user.needsToTakeSurvey = false;
                }
                let isSurveyRequired = false;
                if (userPref.organization) {
                    this.user.preference.organizationName = userPref.organization.orgName;
                    if (userPref.organization.surveyRequired) {
                        isSurveyRequired = true;
                    }
                }
                if (userDetails.events.length > 0) {
                    const lastEvent = new Event();
                    lastEvent._id = '' + userDetails.events[0].eventId ;
                    lastEvent.timestamp = userDetails.events[0].timestamp;
                    lastEvent.value = userDetails.events[0].value;
                    lastEvent.duration = userDetails.events[0].duration;
                    lastEvent.durationadj = userDetails.events[0].durationAdj;
                    lastEvent.name = userDetails.events[0].name;
                    this.user.lastEvent = lastEvent;
                }
                if (cookieString === JSON.stringify(this.user)) {
                    console.log('db user is equal to cookie');
                } else {
                    console.log('returning fetched user: ' + this.user.email);
                    this.userSubject.next(this.user);
                    this.saveUserCookie();
                }
            }
        } else {
            this.activityService.isWaiting = false;
            this.user = null;
            this.cookieService.delete('standata.user');
            this.userSubject.next(this.user);
        }
        /*const kinveyUser = this.userService.getActiveUser();
        if (kinveyUser) {
            this.user = new User();
            if (this.cookieService.check('standata.user')) {
                const userObject = JSON.parse(this.cookieService.get('standata.user'));
                Object.assign(this.user, userObject);
                this.userSubject.next(this.user);
            }
            this.endpointService.execute('getUserDetails')
                .then( userDetails => {
                    this.activityService.isWaiting = false;
                    if (userDetails['_id']) {
                        console.log(userDetails);
                        this.user.email = userDetails['email'];
                        this.user.isAdmin = userDetails['isAdmin'];
                        this.user.isOrgAdmin = userDetails['isOrgAdmin'];
                        const preference: Preference = userDetails['preference'];
                        if (preference) {
                            console.log(preference);
                            this.user.preference = preference;
                        } else {
                            console.log('creating new preference');
                            const newPreference: Preference = new Preference();
                            this.user.preference = newPreference;
                            const store = this.dataStoreService.collection('Preferences', DataStoreType.Network);
                            store.save(Object.assign( {}, newPreference));
                        }
                        if (!this.user.preference.lastSurvey || this.user.preference.lastSurvey.length === 0 ||
                            (this.user.preference.queuedSurvey && this.user.preference.queuedSurvey.length) > 0) {
                            this.user.needsToTakeSurvey = true;
                        } else {
                            this.user.needsToTakeSurvey = false;
                        }
                        const organization: Organization = userDetails['organization'];
                        if (organization) {
                            console.log(organization);
                            this.user.isSurveyRequired = organization.SurveyRequired;
                        }
                        this.user.lastEvent = userDetails['lastEvent'];
                        console.log('returning fetched user: ' + this.user.email);
                        this.userSubject.next(this.user);
                        const userString = JSON.stringify(this.user);
                        this.cookieService.set('standata.user', userString, moment().add( 10, 'years').toDate());
                    } else {
                        console.log('empty user detail returned -- why?');
                        console.log(userDetails);
                        alert('User data could not be retrieved. Please refresh the page or try logging in again.');
                    }

                })
                .catch(reason => {
                    this.activityService.isWaiting = false;
                    console.log(reason);
                });

        } else {
            this.activityService.isWaiting = false;
            this.user = null;
            this.cookieService.delete('standata.user');
            this.userSubject.next(this.user);
        }*/
    }

    saveUserCookie() {
        const userString = JSON.stringify(this.user);
        this.cookieService.set('standata.user', userString, moment().add( 10, 'years').toDate());
    }

    async saveUser(userToSave: User) {
        console.log('saving user...');
        this.user = userToSave;
        /*const store = this.dataStoreService.collection('Preferences', DataStoreType.Network);
        store.save(Object.assign( {}, this.user.preference))
            .then( (preference: Preference) => {
                this.user.preference = preference;
            })
            .catch((error) => {
                console.log(error);
            });*/
        const userPref = {
            height: this.user.preference.myHeight,
            sitInterval: this.user.preference.sitInterval,
            standInterval: this.user.preference.standInterval,
            memoryOne: this.user.preference.memoryOne,
            memoryTwo: this.user.preference.memoryTwo,
            birthYear: this.user.preference.birthYear,
            datePurchase: this.user.preference.purchaseDate,
            dateSurvey: this.user.preference.lastSurvey,
            queuedSurveyId: this.user.preference.queuedSurveyId
        };
        const response = await this.apiService.putUserPref(userPref).toPromise();
        const preference: Preference = {
            myHeight: response.height,
            sitInterval: response.sitInterval,
            standInterval: response.standInterval,
            memoryOne: response.memoryOne,
            memoryTwo: response.memoryTwo,
            birthYear: response.birthYear,
            purchaseDate: response.datePurchase,
            lastSurvey: response.dateSurvey,
            queuedSurveyId: response.queuedSurveyId,
            _id: this.user.preference._id,
            organizationName: this.user.preference.organizationName,
            optIn: this.user.preference.optIn,
            email: this.user.preference.email
        };
        this.user.preference = preference;
    }
}
